
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import CoreErrorDisplay from '@bishop/core-ui/core-error-display'
import CoreInputField from '@bishop/core-ui/core-input-field'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import useTranslation from 'next-translate/useTranslation'
import {useRouter} from 'next/router'
import {ChangeEvent, useState} from 'react'
import {useForm} from 'react-hook-form'
import Card from '../components/card'
import FilledButton from '../components/filled-button'
import Link from '../components/link'
import useUser from '../hooks/use-user'
import UnauthenticatedLayout from '../layouts/unauthenticated-layout'
import fetchJson from '../lib/fetch-json'

type LoginFormData = {
  email: string
  password: string
  rememberMe?: boolean
}

export default function Login() {
  const router = useRouter()
  const {mutateUser} = useUser({
    redirectIfFound: true,
    redirectTo: (router.query?.referrer as string) || '/',
  })
  const {t} = useTranslation('login')
  const {
    register,
    setValue,
    getValues,
    formState: {isSubmitting, errors},
    handleSubmit: onSubmit,
  } = useForm<LoginFormData>({
    reValidateMode: `onBlur`,
    defaultValues: {rememberMe: true},
  })
  const defaultRememberMeChecked = getValues(`rememberMe`)
  const [error, setError] = useState<Error>()

  function handleRememberMeChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target) {
      setValue(`rememberMe`, event.target.checked)
    }
  }

  async function handleSignIn(formData: LoginFormData) {
    setError(undefined)

    try {
      const data = await fetchJson<{token: string}>(
        `${process.env.NEXT_PUBLIC_API_URL}/api/auth`,
        {
          method: `POST`,
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
          }),
          headers: {'Content-Type': 'application/json'},
        },
      )

      if (typeof window !== 'undefined') {
        const browserStorage = formData.rememberMe
          ? window.localStorage
          : window.sessionStorage

        browserStorage.setItem(`token`, data.token)

        mutateUser()
      }
    } catch (error) {
      setError(new Error(error.message))
    }
  }

  return (
    <UnauthenticatedLayout
      position="relative"
      seoProps={{title: t(`pageTitle`)}}
    >
      <Container
        maxWidth="sm"
        sx={{
          position: `absolute`,
          top: `50%`,
          transform: `translateY(-50%)`,
          left: 0,
          right: 0,
          mx: `auto`,
        }}
      >
        <Card>
          <Box
            sx={{display: `grid`, gap: 1}}
            component="form"
            onSubmit={onSubmit(handleSignIn)}
          >
            {error && (
              <CoreErrorDisplay id="login-error" sx={{mb: 2, mx: 0, mt: 0}}>
                {t(`common:error.${error.message}`)}
              </CoreErrorDisplay>
            )}

            <CoreInputField
              id="email"
              type="email"
              label={t(`common:form.emailAddress`)}
              placeholder={t(`common:placeholder.yourEmailAddress`)}
              {...register(`email`, {
                required: {
                  value: true,
                  message: t(`common:form.validation.field.required`),
                },
              })}
              error={Boolean(errors.email)}
              helperText={errors?.email?.message}
            />

            <CoreInputField
              id="password"
              type="password"
              label={t(`common:form.password`)}
              placeholder={t(`common:placeholder.yourPassword`)}
              {...register(`password`, {
                required: {
                  value: true,
                  message: t(`common:form.validation.field.required`),
                },
              })}
              error={Boolean(errors.password)}
              helperText={errors?.password?.message}
            />

            <Box
              sx={{
                display: `grid`,
                gridAutoFlow: `column`,
                justifyContent: `space-between`,
                alignItems: `center`,
              }}
            >
              <FormControlLabel
                control={<Checkbox defaultChecked={defaultRememberMeChecked} />}
                label={t(`common:form.rememberMe`)}
                onChange={handleRememberMeChange}
              />

              <Link href="/reset-password">{t(`forgotPassword`)}</Link>
            </Box>

            <FilledButton
              type="submit"
              color="primary"
              sx={{alignSelf: `center`, width: `180px`, mx: `auto`}}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                t(`common:form.signIn`)
              )}
            </FilledButton>
          </Box>
        </Card>
      </Container>
    </UnauthenticatedLayout>
  )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  